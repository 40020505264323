import React from "react";
import { css } from "@emotion/core";

import Layout from "../components/layout";

const NotFoundPage = () => (
  <Layout>
    <h3
      css={css`
        font-family: Lato, sans-serif;
        font-weight: bold;
        font-size: 32pt;
      `}
    >
      Niet gevonden
    </h3>
  </Layout>
);

export default NotFoundPage;
